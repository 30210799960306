import React, { useState, useEffect }  from 'react'
const IS_LEGAL_DRINKING_AGE = 'IS_LEGAL_DRINKING_AGE';

export default function LegalAge() {   
    const [isLegalDrinkingAge, setLegalDrinkingAge] = useState(false);
	const [isLoadingLegalDrinkingAge, setIsLoadingLegalDrinkingAge] = useState(true);

    // Use effect hook that runs once, gets if user is of legal drinking age
	useEffect(() => {
		// Get current favorites and save to state.
        const currLegalDrinkingAge = localStorage.getItem(IS_LEGAL_DRINKING_AGE);
        setLegalDrinkingAge(currLegalDrinkingAge === 'true');
        if (currLegalDrinkingAge === null || currLegalDrinkingAge === 'false') {
            document.body.style.overflow = "hidden";
        }
        setIsLoadingLegalDrinkingAge(false);
    }, []);
    
    function toggleLegalDrinkingAge (value) {
        if (value) {
            document.body.style.overflow = "";
        }
        setLegalDrinkingAge(value);
        localStorage.setItem(IS_LEGAL_DRINKING_AGE, value)
    }

    if (isLegalDrinkingAge || isLoadingLegalDrinkingAge) {
        return null
    }

    return (
        <div className="ageOverlay">
            <div className="modal">
                <h3>Are you of age to responsibly enjoy our handcrafted spirits<span className="dot">?</span></h3>
                <img src={'../../volcanic.svg'} alt="Volcanic Logo" className="volcanicLogoAge"/>
                <p className="confirmText">I confirm that I am of legal drinking age in my country or state of residence.</p>
                <div className="ageButtons">
                    <button onClick={() => toggleLegalDrinkingAge(true)}>YES</button>
                    <button onClick={() => toggleLegalDrinkingAge(false)}>NO</button>
                </div>
            </div>
        </div>
    )
}
