import { Link, graphql, useStaticQuery  } from "gatsby"
import React from "react"
import { RichText } from "prismic-reactjs"

const query = graphql`
  {
    prismic {
      allProducts {
        edges {
          node {
            _meta {
              id
              uid
            }
            name
            menu_title
          }
        }
      }
    }
  }
`

const Header = () => {
  const data = useStaticQuery(
    query
  )
  if (!data) return null
  return (
  <header>
    <div>
        <Link to="/" >
          <img src={'../../volcanic.svg'} alt="Volcanic Logo" className="volcanicLogo"/>
        </Link>
        <div className="menu">
          {data.prismic.allProducts.edges.map((product, index) => {
            return(
              <Link 
                key={index}
                to={"/"+product.node._meta.uid}
                activeClassName={"menuItemActive"}
              >
                  <h2 className="menuItem" key={product.node._meta.id}>{RichText.asText(product.node.menu_title)}</h2>
              </Link>
            )
          })}
          <Link 
            to="/about"
            activeClassName={"menuItemActive"}
          >
            <h2 className="menuItem">About</h2>
          </Link>
        </div>
    </div>
  </header>
  )
}

export default Header

