/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import LegalAge from '../components/legalAge'
import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <LegalAge/>
      <div className="mainContainer">
        <Header siteTitle={data.site.siteMetadata.title} />
        <div>
          <main>{children}</main>
          <footer>
            <div className="footerLeft">
            </div>
            <div className="footerRight">
              <a href = "mailto: info@volcanicdrinks.com">
                <p className="email">info@volcanicdrinks.com</p>
              </a>
              <a href="https://www.facebook.com/volcanic.energy">
                <p className="email">facebook</p>
              </a>
              <a href="https://www.instagram.com/volcanicdrinks/">
                <p className="email">instagram</p>
              </a> 
            </div>
          </footer>
        </div>
      </div>
    </>
  )
}

export default Layout
